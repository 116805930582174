<template lang="html">
    <div class="container main">
        <top-navigation-bar
            title="Regression"
            right-nav="reset"
            @click="doReset"
        />
        <div class="row row-cols-2">
            <div class="col m-view">
                x̄ = {{ allData.output.xBar }}
            </div>
            <div class="col m-view">
                ȳ = {{ allData.output.yBar }}
            </div>
            <div class="col m-view">
                n = {{ allData.output.n }}
            </div>
            <div class="col m-view">
                r = {{ allData.output.r }}
            </div>
        </div>
        <div class="m-view pt-3 pb-3 border border-dark">
            ŷ = {{ allData.regrEquation[1] }}{{ allData.eqSign }}{{ allData.regrEquation[0] }}
            <input
                v-model="xInput"
                class="eq-inline-input"
                placeholder="x"
                @keyup.enter="onSubmitEdit()"
            > = {{ xOutput }}
        </div>

        <div>
            <highcharts
                class="py-4"
                :options="charts.chartOptions"
            />
        </div>

        <div class="d-flex justify-content-start mt-5 pb-2">
            <button
                class="action-btn w-25"
                @click="morePressed()"
            >
                More
            </button>
        </div>
    </div>
</template>

<script>
import TopNavigationBar from '@/components/top-navigation-bar.vue'
import rData from '@/js/regression/regression-data.js'
import localStore from '@/store/localStore.js'
import { isEmptyNumsCustom, clearData } from '@/js/lib/input-check.js'
import { Chart } from 'highcharts-vue'
import _ from 'lodash'

export default {
    name: 'TableOutput',
    components: {
        TopNavigationBar,
        highcharts: Chart,
    },
    data () {
        return {
            xInput: '',
            xOutput: '',
            xLine: '',
            yLine: '',
            allData: [],
        }
    },
    computed: {
        maxPlot () {
            const maxVal = _.get(this.allData, 'maxXdata', '')
            var item = {
                color: 'red',
                width: 0,
                value: maxVal,
                label: {
                    text: `Max: ${maxVal}`,
                    textAlign: 'center',
                    align: 'center',
                    verticalAlign: 'bottom',
                    rotation: 0,
                    y: 40,
                    style: {
                        color: 'red',
                    }
                }
            }
            return item
        },
        charts () {
            var hideLines = rData.dataHasNegative(this.allData.items)
            const displayColor = hideLines ? 'transparent' : 'blue'
            var xyLineStyle = {
                showInLegend: !hideLines,
                color: displayColor,
                lineWidth: 2,
                dashStyle: 'ShortDash',
                marker: {
                    enabled: true,
                    symbol: 'circle',
                    lineColor: displayColor,
                    lineWidth: 3
                },
            }
            return {
                chartOptions: {
                    xAxis: {
                        title: {
                            text: this.allData.xDesc
                        },
                        min: _.get(this.allData, 'minXdata', 0),
                        plotLines: [this.maxPlot]
                    },
                    yAxis: {
                        min: _.get(this.allData, 'minYdata', 0),
                        title: {
                            text: this.allData.yDesc
                        },
                    },
                    series: [{
                        type: 'line',
                        name: 'Regression Line',
                        data: this.allData.regrData.points,
                        color: '#000000',
                        marker: {
                            enabled: false
                        },
                        states: {
                            hover: {
                                lineWidth: 0
                            }
                        },
                        enableMouseTracking: false
                    }, {
                        type: 'scatter',
                        name: 'Data',
                        data: this.allData.scatterData,
                        color: '#000000',
                        marker: {
                            radius: 4,
                            symbol: 'circle',
                        }
                    },
                    _.assign({
                        type: 'line',
                        name: 'x Value',
                        data: _.get(this, 'xLine', []),
                        tooltip: {
                            headerFormat: '<b>{series.name}</b><br>',
                            pointFormat: '{point.x}',
                        },
                    }, xyLineStyle),
                    _.assign({
                        type: 'line',
                        name: 'Computed y',
                        data: _.get(this, 'yLine', []),
                        tooltip: {
                            headerFormat: '<b>{series.name}</b><br>',
                            pointFormat: '{point.y}',
                        },
                    }, xyLineStyle)],
                }
            }
        },
    },
    beforeMount () {
        const store = localStore.getStore('regression-data')
        const xVals = _.get(store, 'xVals', '')
        this.xInput = xVals?.xInput
        this.xOutput = xVals?.xOutput

        const dataSS = rData.calculateSS(store.items)
        const mb = [dataSS.m, dataSS.b]
        const dataY = rData.getDataY(store.items)
        const dataX = rData.getDataX(store.items)
        const maxYdata = _.max(dataY)
        const minYdata = _.min(dataY)
        const maxXdata = _.max(dataX)
        const minXdata = _.min(dataX)
        const minXdomain = rData.getMinDomainX(dataX)
        const maxXdomain = rData.getMinDomainX(dataX)
        const output = {
            xBar: rData.calculateXbar(store.items),
            yBar: rData.calculateYbar(store.items),
            n: store.items.length,
            r: rData.calculateR(store.items)
        }
        var calculatedData = {
            items: store.items,
            xDesc: store.xDesc,
            yDesc: store.yDesc,
            regrData: rData.calculateRegression(store.items),
            scatterData: rData.groupValsArr(store.items),
            regrEquation: mb,
            eqSign: rData.sign(mb[0]),
            df: output.n - 2,
            dataSS,
            maxYdata,
            minYdata,
            maxXdata,
            minXdata,
            minXdomain,
            maxXdomain,
            output
        }
        this.allData = calculatedData
    },
    methods: {
        doReset (keepData) {
            var inputs = _
                .chain(this.$data)
                .omit('allData')
                .keys()
                .value()

            if (keepData) {
                inputs = _.difference(inputs, keepData)
            }
            _.assign(this, clearData(this, inputs))
        },
        morePressed () {
            const { allData, xInput, xOutput } = this
            _.set(allData, 'xVals', { xInput, xOutput })
            localStore.setStoreItem('regression-data', allData)
            this.$router.push({ name: 'regression/more' })
        },
        onSubmitEdit () {
            var { allData, xInput } = this
            var regrEquation = allData.regrEquation

            if (rData.isInBounds(xInput, allData.items) || isEmptyNumsCustom(xInput)) {
                this.doReset(['xInput'])
                return
            }
            var xOutput = regrEquation[1] + regrEquation[0] * xInput
            xInput = _.toNumber(xInput)
            xOutput = _.toNumber(xOutput.toFixed(2))

            // Graphing
            var meetPoint = { x: xInput, y: xOutput }
            var yLine = [meetPoint, { x: 0, y: xOutput }]
            var xLine = [meetPoint, { x: xInput, y: 0 }]

            var output = { xInput, xOutput, xLine, yLine }
            _.assign(this, output)
        },

    }
}
</script>
